import React from 'react';

import * as styles from './index.module.css';

const PRBadge = () => {
  return (
    <div className={styles['root']}>
      <span className={styles['text']}>記事提供：スポーツ安全協会</span>
    </div>
  );
};

export default PRBadge;
