import React from 'react';

import SeriesListBlock from '../../molecules/series/SeriesListBlock';

import * as styles from './index.module.css';

const SeriesList = ({ series, eager }) => {
  return (
    <div>
      <ul className={styles['list']}>
        {series.map((ser, index) => (
          <li className={styles['listItem']} key={ser.id}>
            <SeriesListBlock series={ser} eager={!!eager && index === 0} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeriesList;
