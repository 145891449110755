import React from 'react';

import * as styles from './index.module.css';

const ExpertNameplate = ({ expert }) => {
  const { name, title } = expert;

  return (
    <div className={styles['root']}>
      <div>
        <span className={styles['name']}>{name} 氏</span>
      </div>

      {title && (
        <div className={styles['titleWrapper']}>
          <span className={styles['title']}>{title}</span>
        </div>
      )}
    </div>
  );
};

export default ExpertNameplate;
