import React from 'react';

import SeriesListBlockArticleList from '../SeriesListBlockArticleList';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import LinkButtonPrimary from '../../../atoms/LinkButtonPrimary';
import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const SeriesListBlock = ({ series, eager }) => {
  const {
    id,
    slug,
    title,
    lede,
    mainImage,
    articles,
    backgroundColor,
  } = series;

  return (
    <div
      className={styles['root']}
      style={backgroundColor && { backgroundColor }}
    >
      <div className={styles['infoArea']}>
        <div className={styles['mainImageWrapper']}>
          <ObjectFitCoverImage
            src={mainImage.resize.src}
            alt=""
            width={600}
            height={315}
            loading={eager ? 'eager' : 'lazy'}
          />
        </div>

        <div className={styles['titleWrapper']}>
          <span className={styles['title']}>{title}</span>
        </div>

        <div className={styles['ledeWrapper']}>
          <p className={styles['lede']}>{lede.lede}</p>
        </div>

        <InlineBlockWrapper className={styles['showSeriesLinkWrapper']}>
          <LinkButtonPrimary
            to={`/series/${slug}`}
            label="このシリーズの一覧"
          />
        </InlineBlockWrapper>
      </div>

      <div className={styles['articleListWrapper']}>
        <SeriesListBlockArticleList articles={articles} seriesId={id} />
      </div>
    </div>
  );
};

export default SeriesListBlock;
