import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const BreadcrumbLink = ({ to, label, partiallyActive }) => {
  return (
    <div className={styles['root']}>
      <Link
        className={styles['link']}
        activeClassName={styles['linkActive']}
        partiallyActive={partiallyActive}
        to={to}
      >
        {label}
      </Link>
    </div>
  );
};

export default BreadcrumbLink;
