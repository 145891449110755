import React from 'react';

import CategoryLabel from '../CategoryLabel';
import DateLabel from '../DateLabel';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const CategoryAndPublishedAt = ({ category, publishedAt }) => {
  return (
    <div className={styles['root']}>
      <InlineBlockWrapper className={styles['categoryWrapper']}>
        <CategoryLabel category={category} />
      </InlineBlockWrapper>
      <InlineBlockWrapper>
        <DateLabel date={publishedAt} />
      </InlineBlockWrapper>
    </div>
  );
};

export default CategoryAndPublishedAt;
