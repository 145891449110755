import React from 'react';

import * as styles from './index.module.css';

const CategoryLabel = ({ category }) => {
  return (
    <div className={styles['root']}>
      <span className={styles['label']}>{category.name}</span>
    </div>
  );
};

export default CategoryLabel;
