import React from 'react';

import DisableableLink from '../../../components/DisableableLink';

import { ReactComponent as LeftIcon } from '../../../images/icon-arrow-left.svg';
import { ReactComponent as RightIcon } from '../../../images/icon-arrow-right.svg';

import * as styles from './index.module.css';

const Pagination = ({ pagination, basePath }) => {
  const { pageCount, index } = pagination;

  if (pageCount === 1) {
    return null;
  }

  const pageNumber = index + 1;

  const prevDisabled = pageNumber === 1;
  const prevUrl =
    pageNumber > 2 ? `${basePath}/page/${pageNumber - 1}` : `${basePath}`;

  const nextDisabled = pageNumber === pageCount;
  const nextUrl =
    pageNumber < pageCount
      ? `${basePath}/page/${pageNumber + 1}`
      : `${basePath}/page/${pageNumber}`;

  return (
    <div>
      <ul className={styles['list']}>
        <li className={styles['listItem']}>
          <DisableableLink
            className={styles['link']}
            disabledClassName={styles['linkDisabled']}
            disabled={prevDisabled}
            to={prevUrl}
          >
            <div className={styles['iconCircle']}>
              <LeftIcon />
            </div>
            <span className={styles['label']}>前のページ</span>
          </DisableableLink>
        </li>

        <li className={styles['listItem']}>
          <DisableableLink
            className={styles['link']}
            disabledClassName={styles['linkDisabled']}
            disabled={nextDisabled}
            to={nextUrl}
          >
            <span className={styles['label']}>次のページ</span>
            <div className={styles['iconCircle']}>
              <RightIcon />
            </div>
          </DisableableLink>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
