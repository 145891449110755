import React from 'react';
import { Link } from 'gatsby';

const DisableableLink = ({ disabledClassName, ...props }) => {
  const style = props.disabled ? { pointerEvents: 'none' } : {};
  const className = props.disabled
    ? `${props.className} ${disabledClassName}`
    : props.className;

  return <Link {...props} className={className} style={style} />;
};

export default DisableableLink;
