import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as styles from './index.module.css';

const DateLabel = ({ date }) => {
  const dateString = dayjs(date).format('YYYY/M/D');

  return (
    <div className={styles['root']}>
      <time dateTime={date}>
        <span className={styles['label']}>{dateString}</span>
      </time>
    </div>
  );
};

DateLabel.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateLabel;
