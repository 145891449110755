import React from 'react';

import * as styles from './index.module.css';

const MainHeading = ({ heading }) => {
  return (
    <div>
      <h1 className={styles['heading']}>{heading}</h1>
    </div>
  );
};

export default MainHeading;
