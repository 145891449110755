import React from 'react';

import ExpertNameplate from '../ExpertNameplate';

import * as styles from './index.module.css';

const ExpertNameplateGroup = ({ role, experts, keyPrefix }) => {
  return (
    <div>
      <div className={styles['roleWrapper']}>
        <span className={styles['role']}>{role}</span>
      </div>
      <div>
        {experts.map(expert => (
          <div
            className={styles['nameplateWrapper']}
            key={`${keyPrefix}_${expert.id}`}
          >
            <ExpertNameplate expert={expert} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpertNameplateGroup;
