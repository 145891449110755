import React from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import BreadcrumbLink from '../BreadcrumbLink';

import * as styles from './index.module.css';

const Breadcrumb = ({ items }) => {
  return (
    <div>
      <ul className={styles['list']}>
        {items.map(item => (
          <li className={styles['listItem']} key={item.to}>
            <InlineBlockWrapper>
              <BreadcrumbLink to={item.to} label={item.label} />
            </InlineBlockWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
