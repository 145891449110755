import React from 'react';

import SeriesListBlockArticleListCard from '../SeriesListBlockArticleListCard';

import * as styles from './index.module.css';

const SeriesListBlockArticleList = ({ articles, seriesId }) => {
  return (
    <div className={styles['root']}>
      <ul className={styles['list']}>
        {articles.map(article => (
          <li className={styles['listItem']} key={`${seriesId}_${article.id}`}>
            <SeriesListBlockArticleListCard
              article={article}
              seriesId={seriesId}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeriesListBlockArticleList;
