import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';
import SeriesList from '../../organisms/SeriesList';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import MainHeading from '../../molecules/common/MainHeading';
import Pagination from '../../molecules/common/Pagination';

import * as styles from './index.module.css';

const SeriesListTemplate = ({ pageContext }) => {
  const { pagePath, series, pagination } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/series',
      label: 'シリーズ一覧',
      partiallyActive: true,
    },
  ];

  return (
    <PublicRoot>
      <SEO path={pagePath} title="シリーズ一覧" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <section className={styles['section']}>
              <ContainerDefault>
                <div className={styles['headingWrapper']}>
                  <InlineBlockWrapper>
                    <MainHeading heading="シリーズ 一覧" />
                  </InlineBlockWrapper>
                </div>

                <div className={styles['seriesListWrapper']}>
                  <SeriesList series={series} eager="true" />
                </div>

                {pagination.pageCount > 1 && (
                  <div className={styles['paginationWrapper']}>
                    <Pagination pagination={pagination} basePath="/series" />
                  </div>
                )}
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default SeriesListTemplate;
